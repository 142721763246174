import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";
import { LABEL_EVENT } from "../econf/cnf" 

class EventsBrowser extends Component {
    state = {
        selectedTab: "Editions"
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getProducts'}))
    }

    render () {
        var contents = null
        var that = this
        if (this.state.selectedTab === 'Events')
        {
            var lines = this.props.productConfig.events.sort(
                (a,b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }
            ).map (function (p, ic) {
                return <tr key={"event"+ic}  role="button" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEventId", "paramValue": p.id})}}>
                    <td>{p.name}</td>
                    {that.props.isAdmin ? <td><Button onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventId", "paramValue": p.id})}}>Edit</Button></td>:null}
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventId", "paramValue": 0})}}>+ Add</Button>
                <h3>{LABEL_EVENT}</h3>
                <Table responsive className="table-hover listingTable">
                    <thead>
                        <tr>
                            <th>{LABEL_EVENT}</th>
                            {that.props.isAdmin ? <th>Actions</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Editions')
        {
            var pEvent = null
            var lines = this.props.productConfig.editions.sort(
                (a,b) => {
                    const nameA = (a.event.name + '---' + a.subname).toUpperCase();
                    const nameB = (b.event.name + '---' + b.subname).toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }
            ).map (function (p, ic) {
                var similar = p.event.id === pEvent
                pEvent = p.event.id
                return <tr key={"edition"+ic} className={similar ? "tableLine groupped":"tableLine subheader"} role="button" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEditionId", "paramValue": p.id})}}>
                    <td>{similar ? "" : p.event.name}</td>
                    <td>{p.subname}</td>
                    <td>{p.dateStart} {p.dateEnd ? ("- " + p.dateEnd):""}</td>
                    {(that.props.isAdmin || that.props.isMarketing) ? <td>
                        {that.props.isAdmin ? <Button variant="secondary" className="me-3" onClick={(e)=>{e.stopPropagation (); that.props.dispatch (apiRequest({'endpoint': 'duplicate', 'eventEditionId': p.id}))}}>Duplicate</Button>:null}
                        <Button onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": p.id})}}>Edit</Button>
                    </td>:null}
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": 0})}}>+ Add</Button>
                <h3>Editions</h3>
                <Table responsive className="table-hover listingTable">
                    <thead>
                        <tr>
                            <th>{LABEL_EVENT}</th>
                            <th>Edition</th>
                            <th>Dates</th>
                            {(that.props.isAdmin || that.props.isMarketing) ? <th>Actions</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        return <div className="productsBrowser">
            <Nav variant="tabs" justify
                activeKey={this.state.selectedTab}
                onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                <Nav.Item>
                    <Nav.Link eventKey="Events">{LABEL_EVENT}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Editions">Editions</Nav.Link>
                </Nav.Item>
            </Nav>
  
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        productConfig: state.data.productConfig,
        isAdmin: state.data.profile.isAdmin,
        isMarketing: state.data.profile.isMarketing
    }
}

export default connect(mapStateToProps)(EventsBrowser)