import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import LeftMenu from '../interfaceParts/LeftMenu'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AdminBrowser from '../interfaceParts/AdminBrowser'
import ContactsBrowser from '../interfaceParts/ContactsBrowser'
import DashboardBrowser from '../interfaceParts/DashboardBrowser'
import ProductsBrowser from '../interfaceParts/ProductsBrowser'
import EventsBrowser from '../interfaceParts/EventsBrowser'
import SalesBrowser from '../interfaceParts/SalesBrowser'
import TagsBrowser from '../interfaceParts/TagsBrowser'
import TasksBrowser from '../interfaceParts/TasksBrowser'

import Notification from '../interfaceParts/Notification'

import {
  assertLoggedIn,
  redirectTo
} from '../redux/actionsAuth'
import LiveStreamManager from '../interfaceParts/LiveStreamManager'

class InternalPage extends Component {
  componentDidMount() {
    this.props.dispatch(assertLoggedIn())
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile && this.props.profile.CTitle)
      document.title = this.props.profile.CTitle + " CRM"
  }

  render() {

    if (this.props.shouldRedirectTo)
    {
      this.props.dispatch (redirectTo(null))
      return (<Navigate to={this.props.shouldRedirectTo} />)
    }

    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    var contents = null
    if (this.props.pageType === 'AdminPage')
      contents = <AdminBrowser />
    if (this.props.pageType === 'ContactsPage')
      contents = <ContactsBrowser />
    if (this.props.pageType === 'DashboardPage')
      contents = <DashboardBrowser />
    if (this.props.pageType === 'ProductsPage')
      contents = <ProductsBrowser />
    if (this.props.pageType === 'EventsPage')
      contents = <EventsBrowser />
    if (this.props.pageType === 'SalesPage')
      contents = <SalesBrowser />
    if (this.props.pageType === 'TagsPage')
      contents = <TagsBrowser />
    if (this.props.pageType === 'TasksPage')
      contents = <TasksBrowser />
    if (this.props.pageType === 'LiveStreamManagerPage')
      contents = <LiveStreamManager urlParams={document.location.search} />
    
    return (
      <div className={"App" + (this.props.profile.isProgram ? " programUser":"") + (this.props.profile.isSales ? " salesUser":"")}>
          {error}
          <LoadingSpinner show={this.props.loading} />
          <Container fluid>
            <Row className="flex-nowrap">
                <LeftMenu />
                <Col className="py-3 contentPanel">
                  <div className='w-100 h-100 position-absolute contentPanelContents' style={{overflowY:"auto"}}>
                    {contents}
                  </div>
                </Col>
            </Row>
          </Container>
          <Notification/>
          <iframe id="iprint" style={{"height": "0px", "width": "0px", "position": "absolute"}}></iframe>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.error,
    loggedIn: state.auth.loggedIn,
    loading: state.interfaceParams.loading,
    shouldRedirectTo: state.auth.shouldRedirectTo,
    profile: state.data.profile
  }
}


export default connect(mapStateToProps)(InternalPage)


