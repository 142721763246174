import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';

class TagEditModal extends Component {
    textFields = [["label", "Name"],["color", "Color"]]
    state =  { 'color':'#ccc', 'label':'' } 
    
    componentDidMount() {
        var cs = {}
        for (var el of this.props.tagsConfig.external)
        {
            if (el.value === this.props.interface.editingTagId)
            {
                for (var tf of this.textFields)
                    cs[tf[0]] = el[tf[0]]
            }
        }
        for (var el of this.props.tagsConfig.internal)
        {
            if (el.value === this.props.interface.editingTagId)
            {
                for (var tf of this.textFields)
                    cs[tf[0]] = el[tf[0]]
            }
        }
        this.setState (cs)
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    render () {
        var that = this
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTagId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingTagId > 0) ? "Edit tag":"Create tag"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                            {this.textFields.map (function (fn, ic) {
                                return <div className="control" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                <input
                                    id={fn[0]}
                                    className="form-control"
                                    name={fn[0]}
                                    onChange={that.handleInputChange}
                                    value={that.state[fn[0]]}
                                    type="text"
                                />
                            </div>
                            })}
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTagId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'editTag', "tagId": that.props.interface.editingTagId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingTagId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        tagsConfig: state.data.tagsConfig
    }
}

export default connect(mapStateToProps)(TagEditModal)