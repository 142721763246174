import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, apiRequestWithFiles, ON_LOAD_EVENT } from '../redux/actionsAPI';
import {LABEL_EVENT} from '../econf/cnf'

class EventBrowseModal extends Component {
    state =  Object.assign({ selectedTab: "Editions" }) 

    componentDidUpdate(prevProps) {
        
    }
    
    componentDidMount() {
        if (this.props.interface.browsingEventId > 0)
        {
            this.props.dispatch ({type: ON_LOAD_EVENT, openEvent: {}})
            this.props.dispatch (apiRequest({'endpoint': 'getEvent', 'eventId': this.props.interface.browsingEventId}))    
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    render () {
        var that = this
        var contents = null
        
        if (this.state.selectedTab === 'Editions')
        {
            var lines = null;
            if (that.props.openEvent && that.props.openEvent.editions)
                lines = that.props.openEvent.editions.map (function (p, ic) {
                    return <tr key={"edition"+ic}  role="button" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEditionId", "paramValue": p.id})}}>
                        <td>{p.event.name}</td>
                        <td>{p.subname}</td>
                        <td>{p.dateStart} {p.dateEnd ? ("- " + p.dateEnd):""}</td>
                        {that.props.isAdmin ? <td><Button onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": p.id})}}>Edit</Button></td>:null}
                    </tr>
                })
            contents = <div className="mt-3">
                <h3>Editions</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>{LABEL_EVENT}</th>
                            <th>Edition</th>
                            <th>Dates</th>
                            {that.props.isAdmin ? <th>Actions</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Sales')
        {
            var lines = null;
            if (that.props.openEvent && that.props.openEvent.sales)
                lines = that.props.openEvent.sales.map (function (p, ic) {
                    return <tr key={"sale"+ic} role="button" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.id})}}>
                                <td></td>
                                <td>{p.transactionDate}</td>
                                <td>{p.id}</td>
                                <td>{p.products.map (function (pp, ic) { return pp.product.value > 0 ? (pp.product.label + " ") : "" }) } {p.oneTimeProduct ? p.oneTimeProduct : ""}</td>
                                <td>{p.agreedPrice}</td>
                                <td>{p.quantity}</td>
                                <td>{p.contact.label}</td>
                                <td>{p.company.name}</td>
                                <td>{p.note}</td>
                                <td>{(p.by.value > 0) ? p.by.label : p.historicBy }</td>
                                <td>{p.invoiceRef}</td>
                                <td>{(p.invoicePaid || p.cashPaid) ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                                <td>{p.invoiceChecked ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                            </tr>
                })


            contents = <div className="mt-3">
                <h3>Sales</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>ID</th>
                            <th>Products</th>
                            <th>HTVA</th>
                            <th>QTE</th>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>Note</th>
                            <th>By</th>
                            <th>Invoice</th>
                            <th>Paid</th>
                            <th>CHK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        if (this.state.selectedTab === 'Tasks')
        {
            var lines = null;
            if (that.props.openEvent && that.props.openEvent.tasks)
                lines = that.props.openEvent.tasks.map (function (p, ic) {
                    return <tr className={p.hotState ? "task hot": "task"}  key={"task"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                        <td>{p.contact.label}</td>
                        <td>{p.contact.company}</td>
                        <td>{p.product.value > 0 ? p.product.label : ""} </td>
                        {that.props.profile && that.props.profile.isProgram ? null:<td>{p.proposalAmount} {p.proposalDate}</td>}
                        {that.props.profile && that.props.profile.isProgram ? null:<td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>}
                        <td>{p.taskStep.label}</td>
                        <td>{p.nextActionDate}</td>
                        <td>{p.updateDate}</td>
                        <td>{p.user.label}</td>
                    </tr>
                })


            contents = <div className="mt-3">
                <h3>Tasks</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Company</th>
                            <th>Products</th>
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Proposal</th>}
                            {that.props.profile && that.props.profile.isProgram ? null:<th>Is hot</th>}
                            <th>Action</th>
                            <th>Next Action</th>
                            <th>Updated</th>
                            <th>Responsible</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }
        
        return <Modal size={'xl'}  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEventId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{LABEL_EVENT} details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Nav variant="tabs" justify
                        activeKey={this.state.selectedTab}
                        onSelect={(selectedKey) => this.setState ({selectedTab:selectedKey})}>
                        <Nav.Item>
                            <Nav.Link eventKey="Editions">Editions</Nav.Link>
                        </Nav.Item>
                        {this.props.openEvent && this.props.openEvent.tasks && this.props.openEvent.tasks.length > 0 ? <Nav.Item>
                            <Nav.Link eventKey="Tasks">Open tasks</Nav.Link>
                        </Nav.Item>:null}
                        {this.props.openEvent && this.props.openEvent.sales && this.props.openEvent.sales.length > 0 ? <Nav.Item>
                            <Nav.Link eventKey="Sales">Sales</Nav.Link>
                        </Nav.Item>:null}
                    </Nav>
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "browsingEventId", "paramValue": null})}}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        contactConfig: state.data.contactConfig,
        productConfig: state.data.productConfig,
        openEvent: state.data.openEvent,
        tagsConfig: state.data.tagsConfig,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(EventBrowseModal)