import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

class AdminBrowser extends Component {
    state = {
        selectedTab: "Users"
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getUsers'}))
    }

    render () {
        var contents = null
        var that = this
        
        if (this.state.selectedTab === 'Users')
        {
            var lines = this.props.users.sort(
                (a,b) => {
                    const nameA = a.fullName.toUpperCase();
                    const nameB = b.fullName.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }
            ).map (function (p, ic) {
                if (!p.enabled)
                    return null
                return <tr key={"user"+ic}>
                    <td>{p.first_name}</td>
                    <td>{p.last_name}</td>
                    <td>{p.email}</td>
                    <td>{p.phone}</td>
                    <td>{p.function}</td>
                    {that.props.isAdmin ? <td>{p.enabled ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                    {that.props.isAdmin ? <td>{p.isSales ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                    {that.props.isAdmin ? <td>{p.isProgram ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                    {that.props.isAdmin ? <td>{p.isMarketing ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                    {that.props.isAdmin ? <td>{p.isAdmin ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                    <td>{p.tags.map (function(t,icc) { return <span key={"t" + ic + "_" + icc} style={{backgroundColor:that.props.tagsConfig.dicStructure[t.value].color}} className="p-2 rounded me-1 d-inline-block">{that.props.tagsConfig.dicStructure[t.value].label}</span> })}</td>
                    {that.props.isAdmin ? <td><Button onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingUserId", "paramValue": p.id})}}>Edit</Button></td>:null}
                </tr>
            })
            var exLines = null
            if (that.props.isExAdmin)
                exLines = this.props.users.sort(
                    (a,b) => {
                        const nameA = a.fullName.toUpperCase();
                        const nameB = b.fullName.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    }
                ).map (function (p, ic) {
                    if (p.enabled)
                        return null
                    return <tr key={"user"+ic}>
                        <td>{p.first_name}</td>
                        <td>{p.last_name}</td>
                        <td>{p.email}</td>
                        <td>{p.phone}</td>
                        <td>{p.function}</td>
                        {that.props.isAdmin ? <td>{p.enabled ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                        {that.props.isAdmin ? <td>{p.isSales ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                        {that.props.isAdmin ? <td>{p.isProgram ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                        {that.props.isAdmin ? <td>{p.isMarketing ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                        {that.props.isAdmin ? <td>{p.isAdmin ? <i className={"fs-4 bi-check2"}></i>:null }</td>: null}
                        <td>{p.tags.map (function(t,icc) { return <span key={"t" + ic + "_" + icc} style={{backgroundColor:that.props.tagsConfig.dicStructure[t.value].color}} className="p-2 rounded me-1 d-inline-block">{that.props.tagsConfig.dicStructure[t.value].label}</span> })}</td>
                        {that.props.isAdmin ? <td><Button onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingUserId", "paramValue": p.id})}}>Edit</Button></td>:null}
                    </tr>
                })
            contents = <div className="mt-3">
                {that.props.isAdmin ? <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingUserId", "paramValue": 0})}}>+ Add</Button>:null}
                <h3>Users management</h3>
                <Table responsive className="table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>E-mail / Login</th>
                            <th>Phone</th>
                            <th>Function</th>
                            {that.props.isAdmin ? <th>Enabled</th>:null}
                            {that.props.isAdmin ? <th>Is sales</th>:null}
                            {that.props.isAdmin ? <th>Is program</th>:null}
                            {that.props.isAdmin ? <th>Is marketing</th>:null}
                            {that.props.isAdmin ? <th>Is admin</th>:null}
                            <th>Tags</th>
                            {that.props.isAdmin ? <th>Actions</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                        {exLines}
                    </tbody>
                </Table>
            </div>
        }

        return <div className="usersBrowser">
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        users: state.data.users,
        isAdmin: state.data.profile.isAdmin,
        isExAdmin: state.data.profile.isExAdmin,
        tagsConfig: state.data.tagsConfig
    }
}

export default connect(mapStateToProps)(AdminBrowser)