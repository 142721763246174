import React, { Component } from 'react';
import { connect } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/esm/Button';

import EventEditModal from './EventEditModal';
import EventEditionEditModal from './EventEditionEditModal';
import ProductEditModal from './ProductEditModal';
import CompanyEditModal from './CompanyEditModal';
import ContactEditModal from './ContactEditModal';
import ContactImportModal from './ContactImportModal';
import UserEditModal from './UserEditModal';
import TaskEditModal from './TaskEditModal';
import SaleEditModal from './SaleEditModal';
import TagEditModal from './TagEditModal';
import EventBrowseModal from './EventBrowseModal';
import EventEditionBrowseModal from './EventEditionBrowseModal';

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import {logout, redirectTo} from "../redux/actionsAuth"
import { apiRequest } from "../redux/actionsAPI";
import InvoiceViewer from './InvoiceViewer';
import MailEditor from './MailEditor';
import MailViewer from './MailViewer';

import logo from '../econf/logo.png';
import {LABEL_EVENT} from '../econf/cnf'


class LeftMenu extends Component
{
    render () {
        var that = this
        var cMenu = [   ["Dashboard", "house", "/"],
            ["Contacts", "person-lines-fill", "/contacts"],
            ["Tasks", "list-task", "/tasks"],
            [LABEL_EVENT, "calendar2-range", "/events"],
            ["Products", "box-seam", "/products"],
            ["Users", "gear", "/admin"],
            ["Tags", "tags", "/tags"]]
        if (that.props.profile && (that.props.profile.isSales || that.props.profile.isAdmin))
            cMenu.splice(5, 0, ["Sales", "cart-check", "/sales"]);
        var menuItems = cMenu.map (function (el) {
            return <li className={"nav-item menuItem" + el[0]} key={"leftMenu" + el[0]}>
                        <a href={el[2]} className="nav-link align-middle px-0">
                            <i className={"fs-4 bi-" + el[1]}></i> <span className="ms-1 d-none d-sm-inline">{el[0]}</span>
                        </a>
                    </li>
        })

        if (this.props.profile && this.props.profile.tagFilters)
            menuItems.push (... this.props.profile.tagFilters.map (function (tf, ic) {
                return <li className={"nav-item"} key={"leftMenuTF" + ic}>
                            <a style={{display:"inline-block"}} href="/contacts" className="nav-link align-middle px-0" onClick={(e)=>{e.preventDefault(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedTagFilter", "paramValue": tf.params['tagFilter']}); that.props.dispatch (redirectTo('/contacts'));  }}><i className={"fs-4 bi-funnel"}></i> <span className="ms-1 d-none d-sm-inline">{tf.params.caption}</span></a> <Button variant="link" onClick={(e)=>{ e.stopPropagation(); console.log ('21'); that.props.dispatch (apiRequest({'endpoint': 'deleteWidget', "widgetID": tf.id})) }}><i className={"fs-4 bi-x"}></i></Button>
                        </li>
            }))

        return (
            <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark leftMenu">
                <div className="noPrint d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                    <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <img width={"80%"} src={logo} />
                    </a>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                        {menuItems}
                    </ul>
                    <hr/>
                    <div className="dropdown pb-4">
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-profile">
                                <i className={"fe-4 bi-person-circle"}></i>
                                <span className="d-none d-sm-inline mx-1">{this.props.profile.first_name} {this.props.profile.last_name}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e)=>{that.props.dispatch (logout())}}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>   
                    </div>
                </div>

                {this.props.interface.editingTagId !== null ? <TagEditModal />:null }
                {this.props.interface.editingContactId !== null ? <ContactEditModal />:null }
                {this.props.interface.importingContactId !== null ? <ContactImportModal />:null }
                {this.props.interface.editingCompanyId !== null ? <CompanyEditModal />:null }
                {this.props.interface.editingProductId !== null ? <ProductEditModal />:null }
                {this.props.interface.editingEventEditionId !== null ? <EventEditionEditModal />:null }
                {this.props.interface.editingEventId !== null ? <EventEditModal />:null }
                {this.props.interface.editingUserId !== null ? <UserEditModal />:null }
                {this.props.interface.editingTaskId !== null ? <TaskEditModal />:null }
                {this.props.interface.editingSaleId !== null ? <SaleEditModal />:null }
                {this.props.interface.browsingEventId !== null ? <EventBrowseModal />:null }
                {this.props.interface.browsingEditionId !== null ? <EventEditionBrowseModal />:null }
                {this.props.interface.invoiceViewerShown ? <InvoiceViewer />:null }
                {this.props.interface.sendingMailParams ? <MailEditor />:null }
                {this.props.interface.viewingMailId ? <MailViewer />:null }
                
            </div>
            
        );
    }
}


function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(LeftMenu)
