import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';

import logo from '../econf/inv_logo.png';
import footer from '../econf/inv_footer.png';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';

class InvoiceViewer extends Component {
    uDate = new Date ()
    state =  { sale:null, products:[], contactDetails:null, contactCompany: null}

    numberWithSpaces(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        if (parts.length === 1)
            parts.push ('00')
        else
            while (parts[1].length < 2)
                parts[1] += '0'
            if (parts[1].length > 2)
                parts[1] = parts[1].substr(0,2)
        return parts.join(".");
    }
    
    componentDidMount() {
        if (this.props.interface.editingSaleId > 0)
        {
            var cs = {}
            for (var el of this.props.sales)
            {
                if (el.id === this.props.interface.editingSaleId)
                {
                    cs['sale'] = el
                    this.fillContactDetails (el['contact'].value)
                    this.fillCompanyDetails (el['company'].id ? el['company'].id : 0)
                    cs['products'] = [...el['products']]
                    for (var pp of cs['products'])
                    {
                        for (var p of this.props.productConfig.products)
                        {
                            if (p.id === pp.product.value)
                            {
                                pp['productCard'] = p
                                break
                            }
                        }
                    }
                }
            }
            this.setState (cs)
        }
    }

    fillContactDetails (nID) {
        for (var cc of this.props.contactConfig.contacts)
        {
            if (cc.id === nID)
            {
                this.fillCompanyDetails (cc.company__id)
                this.setState ({contactDetails: cc})
                return
            }
        }
        this.setState ({contactDetails: null})
    }

    fillCompanyDetails (nID) {
        for (var ccomp of this.props.contactConfig.companies)
        {
            if (ccomp.id === nID)
            {
                this.setState ({contactCompany: ccomp})
                return
            }
        }
        this.setState ({contactCompany: null})
    }

    pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    communication () {
        var cID = this.pad (this.state.contactCompany ? this.state.contactCompany.id:99999, 5)
        var sID = this.pad (this.state.sale.id, 4)
        var comb = cID + sID + '0'
        var cc = parseInt(comb, 10) % 97
        if (cc === 0)
            cc = 97
        
        return cID.substr (0,3) + '/' + cID.substr (3) + sID.substr(0,2) + '/' + sID.substr(2) + '0' + this.pad (cc, 2)
    }

    render () {
        var that = this
        var tva = 21
        if (this.state.contactCompany && this.state.contactCompany.vat && (this.state.contactCompany.vat.length > 0) && (this.state.contactCompany.vat.toLowerCase().substr(0,2) !== 'be'))
            tva = 0
        if (this.state.contactCompany && (this.state.contactCompany.isOutOfEU))
            tva = 0
        
        var aContact = null
        if (this.state.contactDetails)
            aContact = this.state.contactDetails.user__first_name + ' ' + this.state.contactDetails.user__last_name
        if (this.state.contactCompany && this.state.contactCompany.invoiceContact)
            aContact = this.state.contactCompany.invoiceContact.firstName + ' ' + this.state.contactCompany.invoiceContact.lastName

        if (!this.state.sale)
            return null
        return (
            <div className='InvoiceViewer noPrint'>
                    <Row className='noPrint'>
                        <Col xs={12} className='text-end'>
                            <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "invoiceViewerShown", "paramValue": false})}}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                    <div className='InvoiceContents'>
                        <Row>
                            <Col xs={3} className='logo'><img src={logo}/></Col>
                        </Row>
                        <Row style={{marginTop:"10px"}}>
                            <Col xs={6} style={{display:'flex', flexDirection:'column', justifyContent: "flex-end"}}>
                                <h3>Draft of invoice</h3>
                                <p>Invoice date: {this.state.sale.invoiceDateR}</p>
                                <p>Client id: CL{this.state.contactCompany ? this.state.contactCompany.id:null}</p>
                                <p>Our reference: {this.state.sale.invoiceRef}</p>
                                <p>Your reference: {this.state.sale.invoiceCRef}</p>
                            </Col>
                            {this.state.contactCompany ? 
                                <Col xs={6} className="text-end" style={{paddingBottom:"50px"}}>
                                    <div className='text-start' style={{"display":"inline-block"}}>
                                        <h3>{this.state.contactCompany.name}</h3>
                                        {this.state.sale.invoiceReceival ? <p>{this.state.sale.invoiceReceival}</p>:null}
                                        <p>{this.state.contactCompany.address}</p>
                                        <p>{this.state.contactCompany.zipCode} {this.state.contactCompany.city}</p>
                                        <p className="mb-3">{this.state.contactCompany.country}</p>
                                        <p>VAT: {this.state.contactCompany ? this.state.contactCompany.vat:""}</p>
                                    </div>
                                </Col>:null }
                        </Row>
                        <Row className='mt-3 border'>
                            <Col xs={8}><b>Product</b></Col>
                            <Col xs={3}><b>Price (excl. VAT)</b></Col>
                            <Col xs={1}><b>VAT %</b></Col>
                        </Row>
                        <Row className='border'>
                            <Col xs={8}>{this.state.products.map (function (pp, ic) { 
                                                return <div>
                                                            <p key={"pppc" + ic}>{pp.productCard.fullName}</p>
                                                            {pp.productCard.description ? <p key={"pppd" + ic} style={{whiteSpace: "pre-line"}}>{pp.productCard.description}</p>:null}
                                                        </div>
                                                            })} 
                                        {(this.state.sale.oneTimeProduct && this.state.sale.oneTimeProduct.length > 2) ? 
                                            <p>{this.state.sale.oneTimeProduct}</p>:null} 
                                        {this.state.sale.membershipStartDate ? <p>
                                            Membership start date: {this.state.sale.membershipStartDate}</p>:null}
                            </Col>
                            <Col xs={3}>€ {that.numberWithSpaces(this.state.sale.agreedPrice)}</Col>
                            <Col xs={1}>{tva}</Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                            </Col>
                            <Col xs={4} className='border'>
                                <Row >
                                    <Col xs={6} className='text-left'>Sub-total</Col>
                                    <Col xs={6} className='text-end'>€ {that.numberWithSpaces(this.state.sale.agreedPrice)}</Col>
                                </Row>
                                <Row >
                                    <Col xs={6} className='text-left'>VAT</Col>
                                    <Col xs={6} className='text-end'>€ {that.numberWithSpaces(this.state.sale.agreedPrice * tva / 100.0)}</Col>
                                </Row>
                                <Row className='border-top'>
                                    <Col xs={6} className='text-left'>Total</Col>
                                    <Col xs={6} className='text-end'>€ {that.numberWithSpaces(this.state.sale.agreedPrice + (this.state.sale.agreedPrice * tva / 100.0))}</Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{marginTop: "50px"}}>
                                <Col xs={12}>
                                    <h4>Amount due:<b> € {that.numberWithSpaces(this.state.sale.agreedPrice + (this.state.sale.agreedPrice * tva / 100.0))}</b></h4>
                                    <h4>IBAN: <b></b></h4>
                                    <h4>Communication: <b>+++{that.communication()}+++</b></h4>
                                    <p>Due date: 7 days from {this.state.sale.invoiceDateR} unless otherwise stated in the signed contract</p>
                                    <p>Sales governed by our sales policy that can be obtained through written demand</p>
                                    {(tva === 0) ? <p>The reason of 0% VAT will be set at the moment of the invoice generation</p>:null}
                                </Col>
                        </Row>
                            
                        
                        <div className='bottomPage'>
                            <Row>
                                <Col xs={12}>
                                    <img width={"100%"} src={footer}/>
                                </Col>
                            </Row>
                        </div>
                    </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        sales: state.data.sales,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        users: state.data.users
    }
}

export default connect(mapStateToProps)(InvoiceViewer)