import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, apiRequestWithFiles, getAttachmentForSale, getInvoiceForSale } from '../redux/actionsAPI';

import moment from "moment"

class SaleEditModal extends Component {
    numFields = [["agreedPrice", "HTVA"]]
    textFields = [["oneTimeProduct", "Product customisation"], ["note","Note"], ["invoiceReceival", "To whom the invoice is addressed (pdf)"], [ "invoiceRef", "Reference"], [ "invoiceCRef", "Client reference (PO)"]]
    uDate = new Date ()
    state =  Object.assign({wereChanges:false, taskID: null, selectedFile: null, hasFile: null, isHidden:false, invoiceMissingField:null, initialBy: null, products: [], contact:{value:0, label: '----'}, contactDetails:null, contactCompany: null, contactCompanySel:{value:0, label: '----'}, 
                            user:{value:0, label: '----'}, invoiceChecked: false, invoiceSent: false, invoicePaid: false, cashPaid:false, product:{value:0, label: '----'},
                            transactionDate: null, transactionDateT: null, invoiceDate: null, invoiceDateT: null, membershipStartDate:null, membershipStartDateT: null}, ...this.textFields.map ((el) => ({ [el[0]]:"" })).concat (...this.numFields.map ((el) => ({ [el[0]]:"" })))) 
    

    componentDidMount() {
        var initialBy = this.props.profile.id
        if (this.props.interface.editingSaleId > 0)
        {
            var cs = {}
            for (var el of this.props.sales)
            {
                if (el.id === this.props.interface.editingSaleId)
                {
                    for (var tf of this.textFields)
                        cs[tf[0]] = el[tf[0]] ? el[tf[0]]: ""
                    for (var tf of this.numFields)
                        cs[tf[0]] = el[tf[0]] ? el[tf[0]]: ""
                    for (var f of ['isHidden', 'contact', 'by', 'lastEditBy', 'invoiceChecked', 'invoicePaid', 'invoiceSent', 'cashPaid', 'products', 'taskID', 'horusTransactionId'])
                        cs[f] = el[f]
                    for (f of ['transactionDate', 'invoiceDate', 'membershipStartDate'])
                    {
                        var v = el[f] ? moment (el[f], 'DD-MM-YYYY').toDate() : null
                        cs[f] = v
                        cs[f+'T'] = v ? v.getTime() : v
                    }
                    cs.hasFile = el['attachmentName']
                    console.log (cs, el)

                    for (var pp of cs['products'])
                    {
                        for (var p of this.props.productConfig.products)
                        {
                            if (p.id === pp.product.value)
                            {
                                pp['productD'] = p
                                break
                            }
                        }
                    }

                    this.fillContactDetails (el['contact'].value)
                    const ccomp = this.fillCompanyDetails (el['company'].id ? el['company'].id : 0)
                    initialBy = el['by'].value

                    for (var ff of [['invoiceRef', 'invoice reference'], ['invoiceCRef', 'client invoice reference'], ['company', 'company'], ['invoiceDate', 'invoice date'], ['agreedPrice', 'product prices'], ['byInitials', 'seller'] ])
                    {
                        if (!el[ff[0]])
                        {
                            cs.invoiceMissingField = ff[1]
                            break
                        }
                    }
                    for (var cf of [["name", "name"], ["address", "address"], ["zipCode", "zip code"], ["city", "city"], ["country", "country"]])
                        if ((!cs.invoiceMissingField) && (!ccomp[cf[0]]))
                            cs.invoiceMissingField = "company " + cf[1]
                    if ((!cs.invoiceMissingField) && (!ccomp["vat"]) && (!ccomp["isOutOfEU"]) && (!ccomp["inBelgiumButWithoutVAT"]))
                        cs.invoiceMissingField = "company VAT"
                    if ((!cs.invoiceMissingField) && (el.products.length === 0) && ((el.oneTimeProduct.length < 2) || (!el.isHidden)))
                        cs.invoiceMissingField = "products"
                    /*
                    if (!cs.invoiceMissingField)
                        for (var pp of el.products)
                            for (var pf of [["description", "descriptions"]])
                                if (!pp.productD[pf[0]])
                                {
                                    cs.invoiceMissingField = "product " + pf[1]
                                    break
                                }
                    */
                    
                }
            }
            this.setState (cs)
        }
        else
        {    
            this.setState ({ "invoiceReceival": "Attn. Accounting Department"})
            if (this.props.interface.selectedContactId) {
                var cc = this.fillContactDetails (this.props.interface.selectedContactId)
                this.setState ({contact: cc})
                this.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedContactId", "paramValue": null});
            }
            if (this.props.interface.selectedCompanyId) {
                this.fillCompanyDetails (this.props.interface.selectedCompanyId)
                this.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedCompanyId", "paramValue": null});
            }
        }
        this.setState ({"initialBy":initialBy})
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value, wereChanges:true });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e, wereChanges:true });
        if (inp.name === 'contact')
            this.fillContactDetails (e.value)
        if (inp.name === 'contactCompanySel')
            this.fillCompanyDetails (e.value)
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name], wereChanges:true });
    };

    fillContactDetails (nID) {
        for (var cc of this.props.contactConfig.contacts)
        {
            if (cc.id === nID)
            {
                this.fillCompanyDetails (cc.company__id)
                this.setState ({contactDetails: cc})
                return {value:cc.id, label: cc.user__last_name + ' ' + cc.user__first_name + " ( " + cc.company__name + ")"}
            }
        }
        this.setState ({contactDetails: null})
        return {value:0, label: '----'}
    }

    fillCompanyDetails (nID) {
        for (var ccomp of this.props.contactConfig.companies)
        {
            if (ccomp.id === nID)
            {
                this.setState ({contactCompany: ccomp, contactCompanySel:{value:ccomp.id, label: ccomp.name}})
                return ccomp
            }
        }
        this.setState ({contactCompany: null, contactCompanySel:{value:0, label: '----'}})
        return null
    }

    updateAgreedPrice (products) {
        var ap = 0
        for (var pp of products) {
            if (pp.agreedPrice)
                ap += pp.quantity * pp.agreedPrice
        }
        this.setState ({agreedPrice:ap})
    }

    render () {
        var that = this

        var invoiceCaption = (this.state.agreedPrice < 0) ? "Credit note":"Invoice"

        var selOptions = {
            'by': that.props.users.filter(function (el) {
                return el.enabled || (el.id === that.state.initialBy)
            }).sort(
                (t1, t2) => (t1.fullName < t2.fullName) ? -1 : (t1.fullName > t2.fullName) ? 1 : 0).map (function (el) {
                return {value:el.id, label: el.fullName}
            }),
            'product': that.props.productConfig.products.map (function (el) {
                return {value:el.id, label: el.fullName}
            })
        }
        return (
            <Modal size='xl' className='noPrint' scrollable={true} show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingSaleId > 0) ? ("Edit transaction " + that.props.interface.editingSaleId):"Create transaction"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <Row>
                            <Col md={4}>
                                <div className="control">
                                    <label htmlFor="pDate" className="form-label">Transaction date</label>
                                    <DatePicker 
                                        selected={that.state.transactionDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({transactionDate:date, transactionDateT: date.getTime()}); }}/>
                                </div>
                                <div className="control membershipRelated">
                                    <label htmlFor="pDate" className="form-label">Membership start date</label>
                                    <DatePicker 
                                        selected={that.state.membershipStartDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({membershipStartDate:date, membershipStartDateT: date.getTime()}); }}/>
                                </div>

                                {[['by', 'By']].map (function (sel, ic) {
                                    return <div className="mb-3" key={"sel_" + ic}>
                                                <label className="form-label">{sel[1]}</label>
                                                <div>
                                                    <Select 
                                                        menuPosition="fixed"
                                                        name={sel[0]}
                                                        id={sel[0]}
                                                        onChange={that.handleSelectChange}
                                                        value={that.state[sel[0]]}
                                                        options={[{value:0, label:"----"}].concat (selOptions[sel[0]])}
                                                    />
                                                </div>
                                            </div>

                                })}

                                {that.props.profile.isExAdmin ? <div className="control">
                                        <input
                                            className="form-check-input"
                                            name="isHidden"
                                            onChange={that.handleCBChange}
                                            checked={that.state['isHidden']}
                                            type="checkbox"
                                        />
                                        <label htmlFor="isHidden" className="form-check-label ms-2">Hidden for users</label>
                                    </div>:null}

                                <div>
                                    <Button style={{zIndex:10, position:"relative", marginTop:"-5px"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{ var cs = [...that.state.products]; cs.push ({"id":-1, "product": {"value":0, "label": '----'}, "agreedPrice":null, "quantity":1}); that.setState ({products: cs});    }}><i className={"fs-4 bi-plus-circle"}></i></Button>
                                    <label className="form-label">Products</label>
                                </div>
                                {that.state.products.map (function (d, ic) {
                                    return <div key={"productLine" + ic} className='productLine mb-3'>
                                        
                                        <div>
                                            <Button style={{zIndex:10, position:"relative"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{ var cs = [...that.state.products]; cs.splice(ic, 1); that.updateAgreedPrice (cs); that.setState ({products: cs});    }}><i className={"fs-4 bi-dash-circle"}></i></Button>
                                            <div className='selectField' >
                                                <Select 
                                                    menuPosition="fixed"
                                                    name="product"
                                                    onChange={(e, inp) => { var pr = that.state.products; pr[ic]["product"] = e;  that.setState({ "products": pr });}}
                                                    value={d["product"]}
                                                    options={[{value:0, label:"----"}].concat (selOptions["product"])}
                                                />
                                            </div>
                                            <div className='priceField'>
                                                <input
                                                    className="form-control"
                                                    name={"price"}
                                                    onChange={e => { var pr = that.state.products; pr[ic]["agreedPrice"] = e.target.value; that.updateAgreedPrice (pr);  that.setState({ "products": pr }); }}
                                                    value={d["agreedPrice"]}
                                                    type="number"
                                                    placeholder='Price'
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>
                                })}

                                <div className="mb-3">
                                    {this.numFields.map (function (fn, ic) {
                                        return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <input
                                            id={fn[0]}
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="number"
                                            disabled={(!that.props.profile.isExAdmin) || (that.state.products.length > 0) || (!that.state.isHidden)}
                                        />
                                    </div>
                                    })}
                                    {this.textFields.map (function (fn, ic) {
                                        return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <input
                                            id={fn[0]}
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="text"
                                            disabled={(fn[0]==='invoiceRef') && (!that.props.profile.isExAdmin)}
                                        />
                                    </div>
                                    })}
                                    {[['invoiceSent', "Sent"], ['invoicePaid', 'Paid'], ['invoiceChecked', 'Checked'], ['cashPaid', 'Paid in cash']].map (function (fn, ic) {
                                        return <div className={"control chI" + fn[0]} key={"cb" + ic}>
                                        <input
                                            id={fn[0]}
                                            className={"form-check-input"}
                                            name={fn[0]}
                                            onChange={that.handleCBChange}
                                            checked={that.state[fn[0]]}
                                            type="checkbox"
                                            disabled={(!that.props.profile.isExAdmin)}
                                        />
                                        <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                    </div>
                                    })}
                                </div> 

                                <div className="control">
                                    <label htmlFor="invoiceDate" className="form-label">{invoiceCaption} date</label>
                                    <DatePicker 
                                        selected={that.state.invoiceDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({invoiceDate:date, invoiceDateT: date.getTime()}); }}/>
                                </div>
                                {that.state.lastEditBy ? <div className="control mt-3">
                                    <label className="form-label">Last edit by: {that.state.lastEditBy}</label>
                                </div>:null}
                                
                                
                            </Col>
                            <Col md={4}>
                                <label className="form-label"><b>Contact</b></label>
                                <div>
                                    <Button style={{zIndex:10, position:"relative"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": 0})}}><i className={"fs-4 bi-plus-circle"}></i></Button>
                                    <Select 
                                        menuPosition="fixed"
                                        name="contact"
                                        id="contact"
                                        onChange={that.handleSelectChange}
                                        value={that.state.contact}
                                        options={[{value:0, label:"----"}].concat (that.props.contactConfig.contacts.map (function (el) {
                                            return {value:el.id, label: el.user__last_name + ' ' + el.user__first_name + " ( " + el.company__name + ")"}
                                        }))}
                                    />
                                </div>
                                { this.state.contactDetails ? <div className='mt-3'>
                                    {[['Last name', 'user__last_name'], ['First name', 'user__first_name'], ['Job title', 'jobTitle'], ['Phone', 'phone'], ['GSM', 'gsm'], ['E-mail', 'user__email']].map (function (el, ic) {
                                        if (!that.state.contactDetails[el[1]])
                                            return null
                                        return <div key={"cDet" + el[1]}>
                                            <label className="form-label">{el[0]} : {that.state.contactDetails[el[1]]}</label>
                                        </div>
                                    })}</div>: null}
                                <div className='mt-3'>
                                    <label className="form-label"><b>Company:</b></label>
                                    <Select 
                                        menuPosition="fixed"
                                        name="contactCompanySel"
                                        id="contactCompanySel"
                                        onChange={that.handleSelectChange}
                                        value={that.state.contactCompanySel}
                                        options={[{value:0, label:"----"}].concat (that.props.contactConfig.companies.map (function (el) {
                                            return {value:el.id, label: el.name}
                                        }))}
                                    />
                                    { this.state.contactCompany ? <div className='mt-3'>
                                        {[['Company name', 'name'], ['VAT', 'vat'], ['Address', 'adress'], ['City', 'city'], ['ZIP', 'zipCode'], ['Country', 'country'], ['Website', 'website']].map (function (el, ic) {
                                            if (!that.state.contactCompany[el[1]])
                                                return null
                                            return <div key={"compDet" + el[1]}>
                                                <label className="form-label">{el[0]} : {that.state.contactCompany[el[1]]}</label>
                                            </div>
                                        })}
                                        <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": this.state.contactCompany.id})}}>Edit company details</Button>
                                    </div>:null}
                                </div>

                            </Col>
                            <Col md={4}>
                                <div>
                                    {that.state.hasFile ? <div><span className='d-inline-block align-top pt-1'>Current attachment:</span> <Button className='py-0 align-top' variant="link" onClick={(e)=>{that.props.dispatch (getAttachmentForSale (that.props.interface.editingSaleId, that.state.hasFile))}}>{that.state.hasFile}</Button></div>:null}
                                    

                                    <div className="control">
                                        <label htmlFor="attachment" className="form-label">{that.state.hasFile ? "Replace ":""} Attachment</label>
                                        <Form.Control type="file" name="attachment" onChange={(e) => {that.setState({ selectedFile: e.target.files[0]});}} accept="*" />
                                    </div>
                                </div>
                                {that.state.taskID ? <div className='mt-3'>
                                    <Button className="btn-secondary" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": that.state.taskID})}}>Browse historic task</Button>
                                </div>:null}
                            </Col>
                        </Row>   
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                    { ((this.props.profile.isAdmin || this.props.profile.isSales) && (that.props.interface.editingSaleId) && (!this.state.wereChanges)) ? 
                        (that.state.invoiceMissingField ? <span className='me-auto highlight'>Fill in {that.state.invoiceMissingField} and update to see the invoice</span>:
                            that.state.horusTransactionId ?
                                <Button variant="secondary" className='me-auto' onClick={(e)=>{that.props.dispatch (getInvoiceForSale (that.props.interface.editingSaleId, "invoice_" + that.props.interface.editingSaleId + '.pdf'))}}>
                                    {invoiceCaption} download
                                </Button>:
                                <Button variant="secondary" className='me-auto' onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "invoiceViewerShown", "paramValue": true})}}>
                                    {invoiceCaption} preview
                                </Button>):null}
                            
                            
                    { ((this.props.profile.isAdmin || this.props.profile.isSales) && (that.props.interface.editingSaleId) && (!this.state.wereChanges)) ? 
                        (that.state.invoiceMissingField ? null:
                            ( that.state.horusTransactionId ? <span>Accounting invoice created</span>:<Button variant="secondary" className='me-auto' onClick={(e)=>{ if (confirm("Are you sure you want to create the accounting invoice? No changes will be possible afterwards")) that.props.dispatch (apiRequest({'endpoint': 'createHorusSale', "saleId": that.props.interface.editingSaleId}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null}))  }}>
                                Create accounting invoice
                            </Button>)):null}
                    
                    { that.props.profile.isExAdmin && that.state.horusTransactionId && (that.props.interface.editingSaleId) && (!this.state.wereChanges) ?  <Button variant="secondary" className='float-start me-3' onClick={(e)=>{ if (confirm("Are you sure you want to create a revert transaction? Please double check the parameters of a new one")) { that.props.dispatch (apiRequest({'endpoint': 'revertSale', "saleId": that.props.interface.editingSaleId})); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null}) }}}>
                            Create a revert transaction
                        </Button>:null}
                    { that.props.profile.isExAdmin && (!that.state.invoiceSent) && (!that.state.horusTransactionId) && (that.props.interface.editingSaleId) && (!this.state.wereChanges) ?  <Button variant="secondary" className='float-start me-3' onClick={(e)=>{ if (confirm("Are you sure you want to delete this sale? This will completely remove it")) that.props.dispatch (apiRequest({'endpoint': 'deleteSale', "saleId": that.props.interface.editingSaleId}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})) }}>
                            Delete transaction
                        </Button>:null}
                    <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})}}>
                        Close
                    </Button>
                    {(this.props.profile.isAdmin || (this.props.profile.id === this.state.initialBy)) ? <Button variant="primary" onClick={(e)=>{ that.props.dispatch (apiRequestWithFiles({'endpoint': 'editSale', "saleId": that.props.interface.editingSaleId, "newState":that.state}, that.state.selectedFile, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})) }}>
                            Update
                        </Button>:null}
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        sales: state.data.sales,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        users: state.data.users,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(SaleEditModal)