import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Creatable from 'react-select/creatable';


import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"


class UserEditModal extends Component {
    textFields = [["first_name", "First Name"],["last_name", "Last Name"], ["email", "e-mail"], ['function', 'Function'], ['phone', 'Phone']]
    state =  Object.assign({
            dateOfBirth:null, dateOfBirthT:null, enabled: true, isSales: true, isProgram: false, isMarketing:false, isAdmin: false, tags: []
        }, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 

    componentDidMount() {
        if (this.props.interface.editingUserId > 0)
        {
            var cs = {}
            for (var el of this.props.users)
            {
                if (el.id === this.props.interface.editingUserId)
                {
                    for (var tf of this.textFields)
                        cs[tf[0]] = el[tf[0]]
                    cs['enabled'] = el.enabled
                    cs['isProgram'] = el.isProgram
                    cs['isMarketing'] = el.isMarketing
                    cs['isSales'] = el.isSales      
                    cs['isAdmin'] = el.isAdmin
                    var f = 'dateOfBirth'
                    var v = el[f] ? moment (el[f], 'DD-MM-YYYY').toDate() : null
                    cs[f] = v
                    cs[f+'T'] = v ? v.getTime() : v
                    cs['tags'] = []
                    for (var t of el.tags)
                        cs['tags'].push (this.props.tagsConfig.dicStructure[t.value])
                }
            }
            this.setState (cs)
        }
        
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    render () {
        var that = this
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingUserId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingUserId > 0) ? "Edit user":"Create user"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                            {this.textFields.map (function (fn, ic) {
                                return <div className="control" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                <input
                                    id={fn[0]}
                                    className="form-control"
                                    name={fn[0]}
                                    onChange={that.handleInputChange}
                                    value={that.state[fn[0]]}
                                    type="text"
                                />
                            </div>
                            })}
                            {[['enabled', 'Enabled'], ["isSales", 'Is sales'], ['isProgram', 'Is program'], ['isMarketing', 'Is marketing'], ['isAdmin', 'Is admin']].map (function (fn, ic) {
                                return <div className="control" key={"cb" + ic}>
                                <input
                                    id={fn[0]}
                                    className="form-check-input"
                                    name={fn[0]}
                                    onChange={that.handleCBChange}
                                    checked={that.state[fn[0]]}
                                    type="checkbox"
                                />
                                <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                            </div>
                            })}
                            <div className="control">
                                    <label htmlFor="dateOfBirth" className="form-label">Date of birth</label>
                                    <DatePicker 
                                        selected={that.state.dateOfBirth} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({dateOfBirth:date, dateOfBirthT: date.getTime()}); }}/>
                                </div>
                                
                            <div className="control">
                                <label htmlFor="tags" className="form-label">Tags</label>
                                <Creatable 
                                    name="tags"
                                    id="tags"
                                    onChange={that.handleSelectChange}
                                    value={that.state.tags}
                                    isMulti={true}
                                    options={that.props.tagsConfig.internal}
                                />
                            </div>
                            
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingUserId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'editUser', "userId": that.props.interface.editingUserId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingUserId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        users: state.data.users,
        tagsConfig: state.data.tagsConfig
    }
}

export default connect(mapStateToProps)(UserEditModal)