import React, { Component } from 'react';
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import zxcvbn from 'zxcvbn'

import '../css/LoginForm.scss';

import {
    auth,
    forgetPass,
    setLoginScreenType
  } from '../redux/actionsAuth'
  


class LoginForm extends Component
{
    state = {
        username:"",
        password:"",
        pScore: {score:0}
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
        
      };

    handleLoginSubmit = e => {
        e.preventDefault(); 
        
        if (this.props.loginScreenType === 'login')
            this.props.dispatch(auth (this.state.username, this.state.password))
        else
            this.props.dispatch(forgetPass (this.state.username))
        
    }

    handleRegisterSubmit = (e)=>{
        e.preventDefault(); 
        this.props.dispatch(register ({mail:this.state.username, password:this.state.password, name:this.state.name, surname:this.state.surname, subscribed:this.state.subscribed}))
    }
    
    render() {
        const mailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var fPassFilled = (this.state.username) && (mailRegExp.test(this.state.username))
        var loginFilled = fPassFilled && this.state.password && this.state.password.length > 3
        
        return (
            <div className="login h-100 d-flex align-items-center justify-content-center">
                <div className="loginForm">
                    <h3 className='mb-3'>Sign in to EBS</h3>
                    <div>
                        <form method="post" onSubmit={this.handleLoginSubmit}>
                            <div className="mb-3">
                                <div className="control">
                                    <label htmlFor="username" className="form-label">Email address</label>
                                    <input
                                        id="username"
                                        className="form-control"
                                        name="username"
                                        required
                                        onChange={this.handleInputChange}
                                        value={this.state.username}
                                        type="email"
                                    />
                                </div>
                            </div>
                            
                            {this.props.loginScreenType === 'login' ? 
                                <div className="mb-3">
                                    <div className="control">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            id="password"
                                            className="form-control"
                                            name="password"
                                            required
                                            onChange={this.handleInputChange}
                                            value={this.state.password}
                                            type="password"
                                        />
                                    </div>
                                </div>:null
                            }

                            <div>
                                { this.props.loginScreenType === 'login' ?
                                    <button type="submit"   className={"float-right btn btn-primary float-end" + (loginFilled ? "" : " disabled")} disabled={!loginFilled} >Login</button>
                                    : <button type="submit"  className={"float-right btn btn-primary float-end" + (fPassFilled ? "" : " disabled")} disabled={!fPassFilled} >Send reset link</button> }

                                {this.props.loginScreenType === 'login' ? 
                                    <button type="button" className="btn btn-secondary me-3 float-end"  onClick={(e)=>{e.stopPropagation(); this.props.dispatch (setLoginScreenType('forget'))}}>Forgot password</button>
                                    :null}
                            </div>    
                        </form>
                    </div>
                </div>
            </div>            
        );
    }

}

function mapStateToProps(state) {
    return {
        loading: state.interfaceParams.loading,
        loginScreenType: state.interfaceParams.loginScreenType
        
    }
}

export default connect(mapStateToProps)(LoginForm)
