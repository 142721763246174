import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select, { NonceProvider } from 'react-select';


import { MODIFY_INTERFACE_PARAM } from "../../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"
import {LABEL_EVENT} from "../../econf/cnf"

class BDMDashboard extends Component {
    state = {
        "productFilter": "",
        "eventFilter": {value:0, label:"Select " + LABEL_EVENT},
        "editionFilter": {value:0, label:"Select edition"},
    }

    numberWithSpaces(x) {
        if (!x)
            return x
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }

    setEdition (eID)
    {
        var found = false
        for (var cEdition of this.props.productConfig.editions)
            if (cEdition.id === eID)
            {
                this.setState({ productFilter: cEdition.shortName, editionFilter: {value:cEdition.id, label:cEdition.subname} });
                found = true
                break
            }
        if (!found)
            this.setState({ productFilter: "" });
    }

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
        if (inp.name === "eventFilter")
        {
            this.setState({ productFilter: "", editionFilter: {value:0, label:"Select edition"}});
            for (var cEvent of this.props.productConfig.events)
                if ((cEvent.id === e.value) && (cEvent.editions.length > 0))
                {
                    for (var ed of cEvent.editions)
                        if (ed.isCurrent)
                        {
                            this.setEdition (ed.id)
                            break
                        }
                }
        }
        if (inp.name === "editionFilter")
        {
            this.setEdition (e.value)
        }
    };

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    componentDidMount() {
        
    }

    componentDidUpdate () {
        if ((this.state.eventFilter.value === 0) && (this.props.productConfig.events.length > 0))
        {
            var d = this.props.productConfig.events[0]
            this.setState({ eventFilter: {value:d.id, label:d.name}, productFilter: "", editionFilter: {value:0, label:"Select edition"}});
            for (var ed of d.editions)
                if (ed.isCurrent)
                {
                    this.setEdition (ed.id)
                    break
                }
        }
    }

    render () {
        var that = this

        var salesUpdateLines = null
        var totalSales = 0
        if (this.props.dashboard.salesUpdate && (this.props.dashboard.salesUpdate.length > 0) && (that.state.productFilter.length >= 5))
        {
            var seenCompanies = []
            var companyToTransactions = {}
            for (var cs of this.props.dashboard.salesUpdate)
            {
                var hasSelProduct = false //(p.oneTimeProduct && ( p.oneTimeProduct.toLowerCase().includes (that.state.productFilter.toLowerCase())) )
                for (var pp of cs.products)
                    hasSelProduct = hasSelProduct || ( pp.product.label.toLowerCase().includes (that.state.productFilter.toLowerCase()) )
                if ((!hasSelProduct))
                    continue

                var cName = cs.company.name
                if (!(cName in companyToTransactions))
                {
                    seenCompanies.push (cName)
                    companyToTransactions[cName] = []
                }
                for (var pp of cs.products)
                    if ( pp.product.label.toLowerCase().includes (that.state.productFilter.toLowerCase()) )
                        companyToTransactions[cName].push ({ sale: cs, pp: pp })
            }

            seenCompanies.sort ()
            var ttSales = []
            for (var cName of seenCompanies)
                ttSales.push (...companyToTransactions[cName])

            var lCompany = null
            var lContact = null
            
            salesUpdateLines = ttSales.map (function(csi, cnt) {
                var cName = csi.sale.company.name
                var res = <tr className="BDMDashBoardLine" key={"su" + cnt} onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": csi.sale.id})}}>
                    <td>{(lCompany === cName) ? null:<Button className="btn-primary" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedContactId", "paramValue": csi.sale.contact.value}); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedCompanyId", "paramValue": csi.sale.company.id}); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": 0})}}>+&nbsp;Sale</Button>}</td>
                    <td>{(lCompany === cName) ? null:<a onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": csi.sale.company.id})}}>{cName}</a>}</td>
                    <td>{(lContact === csi.sale.contact.label) ? null:<a onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": csi.sale.contact.value})}}>{csi.sale.contact.label}</a>}</td>
                    <td>{csi.pp.product.sublabel}</td>
                    <td>{that.numberWithSpaces(csi.pp.agreedPrice)}</td>
                    <td>{(csi.sale.invoicePaid || csi.sale.cashPaid) ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                    <td>{csi.sale.note}</td>
                </tr>
                lCompany = cName
                lContact = csi.sale.contact.label
                totalSales += csi.pp.agreedPrice
                return res
                
            })
        }
        
        
        return <div className="BDMDashboard noPrint">
            
            <div className="mt-3 doPrint salesUpdate" id="salesUpdate">
                <h3>Sales state - {moment().format("DD/MM/YYYY")} {this.state.productFilter.length > 0 ? (' - "' + this.state.productFilter + '"'):""}</h3>
                <div className="noPrint">
                    Filter: 
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="eventFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["eventFilter"]}
                        options={[{value:0, label:"Select event"}].concat (this.props.productConfig.events.map (function (d) { return {value:d.id, label:d.name} })) }
                    />
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="editionFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["editionFilter"]}
                        options={[{value:0, label:"Select edition"}].concat (this.props.productConfig.editions.filter (function (d) { return d.event.id === that.state.eventFilter.value } ).map (function (d) { return {value:d.id, label:d.subname} })) }
                    />
                    <input
                        className="form-control me-3 d-inline-block d-none"
                        name="productFilter"
                        onChange={that.handleInputChange}
                        value={that.state["productFilter"]}
                        type="text"
                        placeholder="Product"
                    />
                    
                </div>
                {(salesUpdateLines) && (that.state.productFilter.length >= 5) ? <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Company</th>
                            <th>Contact</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Paid</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {that.props.profile.isExAdmin && false ? <tr className="totalLine">
                            <td>TOTAL</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{that.numberWithSpaces(totalSales)}</td>
                        </tr>:null}
                        {salesUpdateLines}
                    </tbody>
                </Table>:<h5 className="mt-3">Please select the event and edition above to see the update information</h5>}
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        dashboard: state.data.dashboard,
        productConfig: state.data.productConfig,
    }
}

export default connect(mapStateToProps)(BDMDashboard)