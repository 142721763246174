import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequestWithFiles, apiRequest, getAttachmentForTaskStep, ON_LOAD_TASK_DETAILS } from '../redux/actionsAPI';

import moment from "moment"

class TaskEditModal extends Component {
    textFields = [["expectedAmount", "Expected amount"], ["proposalAmount", "Proposal amount"]]
    uDate = new Date ()
    state =  Object.assign({ nextActionDateChanged:false, selectedFile: undefined, contact:{value:0, label: '----'}, contactDetails:null, contactCompany:null, 
                            user:{value:0, label: '----'}, hotState: false, isSalesTask:false, product:{value:0, label: '----'}, taskStep:{value:0, label: '----'},
                            nextActionDate: null, nextActionDateT: null, endDate: null, endDateT: null, closeDate: null, closeDateT: null, proposalDate: null, proposalDateT: null, updateDate: this.uDate, updateDateT: this.uDate.getTime (), newComment:""}, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 
    
    checkSelectedContact ()
    {
        if (this.props.interface.selectedContactId) {
            this.fillContactDetails (this.props.interface.selectedContactId, true)
            this.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedContactId", "paramValue": null});
        }
    }
    
    componentDidMount() {
        if (this.props.interface.editingTaskId > 0)
        {
            this.props.dispatch (apiRequest({'endpoint': 'getTaskDetails', "taskId": this.props.interface.editingTaskId}))
            var cs = {}
            for (var el of this.props.tasks)
            {
                if (el.id === this.props.interface.editingTaskId)
                {
                    for (var tf of this.textFields)
                        cs[tf[0]] = el[tf[0]]
                    for (var f of ['contact', 'user', 'hotState', 'isSalesTask', 'product', 'taskStep', 'forMembership'])
                        cs[f] = el[f]
                    for (f of ['nextActionDate', 'endDate', 'proposalDate', 'closeDate'])
                    {
                        var v = el[f] ? moment (el[f], 'DD-MM-YYYY').toDate() : null
                        cs[f] = v
                        cs[f+'T'] = v ? v.getTime () : v
                    }
                    this.fillContactDetails (cs['contact'].value)
                }
            }
            this.setState (cs)
        }
        else
        {
            this.props.dispatch({type: ON_LOAD_TASK_DETAILS, taskDetails: {}})
            this.setState ({user:{value:this.props.profile.id, label:this.props.profile.fullName}, isSalesTask:(this.props.profile.isSales && (!this.props.profile.isProgram))})
        }
        this.checkSelectedContact ()
    }

    componentDidUpdate (prevProps) {
        this.checkSelectedContact ()
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
        if (inp.name === 'contact')
            this.fillContactDetails (e.value)
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    fillContactDetails (nID, withValue = false) {
        for (var cc of this.props.contactConfig.contacts)
        {
            if (cc.id === nID)
            {
                for (var ccomp of this.props.contactConfig.companies)
                {
                    if (ccomp.id === cc.company__id)
                    {
                        var cs = {contactDetails: cc, contactCompany: ccomp}
                        if (withValue)
                            cs['contact'] = {value:nID, label: cc.user__last_name + " " + cc.user__first_name}
                        this.setState (cs)
                        return
                    }
                }
                var cs = {contactDetails: cc, contactCompany: null}
                if (withValue)
                    cs['contact'] = {value:nID, label: cc.user__last_name + " " + cc.user__first_name}
                this.setState (cs)
                return
            }
        }
        this.setState ({contactDetails: null, contactCompany: null})
    }

    render () {
        var that = this
        var selOptions = {
            'user': that.props.users.filter(function (el) {
                return el.enabled
            }).sort(
                (t1, t2) => (t1.fullName < t2.fullName) ? -1 : (t1.fullName > t2.fullName) ? 1 : 0).map (function (el) {
                return {value:el.id, label: el.fullName}
            }),
            'product': that.props.productConfig.products.filter(function(el) {
                    return el.eventEdition.isCurrent
                }).map (function (el) {
                return {value:el.id, label: el.fullName}
            }),
            'taskStep': that.props.taskSteps.map (function (el) {
                return {value:el.id, label: el.name}
            })
        }
        return (
            <Modal size='xl' scrollable={true} show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingTaskId > 0) ? ("Edit task " + that.props.interface.editingTaskId):"Create task"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <Row>
                            <Col md={4}>
                                {[['product', 'Product'], ['taskStep', 'Last action']].map (function (sel, ic) {
                                    return <div className="mb-3" key={"sel_" + ic}>
                                                <label className="form-label"><b>{sel[1]}</b></label>
                                                <div>
                                                    <Select 
                                                        className={'sel_' + sel[0]}
                                                        menuPosition="fixed"
                                                        name={sel[0]}
                                                        id={sel[0]}
                                                        onChange={that.handleSelectChange}
                                                        value={that.state[sel[0]]}
                                                        options={[{value:0, label:"----"}].concat (selOptions[sel[0]])}
                                                    />
                                                </div>
                                            </div>

                                })}

                                <div className="control">
                                    <label htmlFor="pDate" className="form-label"><b>Next action date*</b></label>
                                    <DatePicker 
                                        selected={that.state.nextActionDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({nextActionDate:date, nextActionDateT: (date ? date.getTime() : null), nextActionDateChanged: true}); }}/>
                                </div>

                                
                                <div className="control">
                                    <label htmlFor="eDate" className="form-label">Task end date</label>
                                    <DatePicker 
                                        selected={that.state.endDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({endDate:date, endDateT: (date ? date.getTime() : null)}); }}/>
                                </div>
                                <div className="control">
                                    <label htmlFor="eDate" className="form-label">Task closed date</label>
                                    <DatePicker 
                                        selected={that.state.closeDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({closeDate:date, closeDateT: (date ? date.getTime() : null)}); }}/>
                                </div>
                                
                            </Col>
                            <Col md={4}>
                                {that.props.profile.isSales ? 
                                    <div className="control mb-3 mt-3">
                                        <input
                                            className="form-check-input"
                                            name="isSalesTask"
                                            onChange={that.handleCBChange}
                                            checked={that.state.isSalesTask}
                                            type="checkbox"
                                        />
                                        <label htmlFor="isSalesTask" className="form-check-label ms-2">Is a sales task</label>
                                    </div>:null
                                }
                                
                                {that.state.isSalesTask ?
                                    <div className="mb-3">
                                        {this.textFields.map (function (fn, ic) {
                                            return <div className="control" key={"tf" + ic}>
                                            <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                            <input
                                                id={fn[0]}
                                                className="form-control"
                                                name={fn[0]}
                                                onChange={that.handleInputChange}
                                                value={that.state[fn[0]]}
                                                type="number"
                                            />
                                        </div>
                                        })}
                                        {[['hotState', 'Is hot'], ['forMembership', 'To become member']].map (function (fn, ic) {
                                            return <div className="control" key={"cb" + ic}>
                                            <input
                                                id={fn[0]}
                                                className="form-check-input"
                                                name={fn[0]}
                                                onChange={that.handleCBChange}
                                                checked={that.state[fn[0]]}
                                                type="checkbox"
                                            />
                                            <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                        </div>
                                        })}
                                    </div> : null
                                }

                                {that.state.isSalesTask ? <div className="control">
                                    <label htmlFor="pDate" className="form-label">Proposal date</label>
                                    <DatePicker 
                                        selected={that.state.proposalDate} 
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => { that.setState ({proposalDate:date, proposalDateT: (date ? date.getTime() : null)}); }}/>
                                </div>:null}
                            </Col>
                            <Col md={4}>
                                <label className="form-label"><b>Contact</b></label>
                                <div>
                                    <Button style={{zIndex:10, position:"relative"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": 0})}}><i className={"fs-4 bi-plus-circle"}></i></Button>
                                    <Select 
                                        menuPosition="fixed"
                                        name="contact"
                                        id="contact"
                                        onChange={that.handleSelectChange}
                                        value={that.state.contact}
                                        options={[{value:0, label:"----"}].concat (that.props.contactConfig.contacts.map (function (el) {
                                            return {value:el.id, label: el.user__last_name + ' ' + el.user__first_name + " ( " + el.company__name + ")"}
                                        }))}
                                    />
                                </div>
                                { this.state.contactDetails ? <div className='mt-3'>
                                    {[['Last name', 'user__last_name'], ['First name', 'user__first_name'], ['Job title', 'jobTitle'], ['Phone', 'phone'], ['GSM', 'gsm'], ['E-mail', 'user__email']].map (function (el, ic) {
                                        if (!that.state.contactDetails[el[1]])
                                            return null
                                        return <div key={"cDet" + el[1]}>
                                            <label className="form-label">{el[0]} : {(el[1]==='user__email') ? <a href={'mailto:' + that.state.contactDetails[el[1]]}>{that.state.contactDetails[el[1]]}</a>:that.state.contactDetails[el[1]]}</label>
                                        </div>
                                    })}
                                    { this.state.contactCompany ? <div className='mt-3'>
                                    <label className="form-label"><b><a href="#" onClick={(e)=>{e.preventDefault (); that.props.dispatch({type:MODIFY_INTERFACE_PARAM, "paramName":"editingCompanyId", "paramValue": that.state.contactCompany.id})}}>Company:</a></b></label>
                                        {[['Company name', 'name'], ['VAT', 'vat'], ['Address', 'adress'], ['City', 'city'], ['ZIP', 'zipCode'], ['Country', 'country'], ['Website', 'website']].map (function (el, ic) {
                                            if (!that.state.contactCompany[el[1]])
                                                return null
                                            return <div key={"compDet" + el[1]}>
                                                <label className="form-label">{el[0]} : {that.state.contactCompany[el[1]]}</label>
                                            </div>
                                        })}
                                        { that.state.contactCompany.memberUntil ? <div key={"compDet memb"}>
                                                <label className="form-label">Member until: {that.state.contactCompany.memberUntil}</label>
                                            </div>:
                                            (that.state.contactCompany.lastMembershipTransaction ? <div key={"compDet memb"}>
                                            <label className="form-label">Was member</label>
                                        </div>: null)   }
                                    </div>:null}
                                    { this.state.contactCompany ? <div className='mt-3'>
                                    <label className="form-label">Collaborators:</label>
                                        {this.state.contactCompany.contacts.map (function (el, ic) {
                                            return <div key={"compCont" + el[1]}>
                                                <Button variant="link"  onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": el[1]})}}>{el[0]}</Button>
                                            </div>
                                        })}
                                    </div>:null}
                                </div>: null}
                                
                            </Col>
                            
                        </Row>   
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                    <div className='flex-grow-1'>
                        <Row>
                            <Col>
                                <div className="control">
                                    <label htmlFor="newComment" className="form-label">Update comment</label>
                                    <input
                                        id="newComment"
                                        className="form-control"
                                        name="newComment"
                                        onChange={that.handleInputChange}
                                        value={that.state["newComment"]}
                                        type="text"
                                    />
                                </div>
                                <div className="control">
                                    <label htmlFor="attachment" className="form-label">Attachment</label>
                                    <Form.Control type="file" name="attachment" onChange={(e) => {that.setState({ selectedFile: e.target.files[0]});}} accept="*" />
                                </div>
                            </Col>
                            <Col className='d-none'>
                                <div className="control">
                                    <label htmlFor="uDate" className="form-label">Date of current update</label>
                                    <DatePicker 
                                        selected={that.state.updateDate} 
                                        dateFormat="dd-MM-yyyy HH-mm"
                                        showTimeSelect
                                        onChange={(date) => { that.setState ({updateDate:date, updateDateT: (date ? date.getTime() : null)}); }}/>
                                </div>
                            </Col>
                            
                        </Row>
                        {(this.props.taskDetails && this.props.taskDetails.events && (this.props.taskDetails.events.length > 0)) ? <Row className='taskHistoryContainer mt-3'><Col md={12}>
                                <h4>Task history</h4>
                                {this.props.taskDetails.events.map (function (ev, ic) {
                                    return <div className='mb-3 taskHistoryLine' key={"h" + ic}>
                                        {ev.stepChangesLines.map (function (line, icc) {
                                            return <div key={"h" + ic + "_" + icc} className={line.includes ("Comment: ") ? "highlighted":""}>{line}</div>
                                        })}
                                        {ev.attachmentName ? <div><span className='d-inline-block align-top pt-1'>Attachment:</span> <Button className='py-0 align-top' variant="link" onClick={(e)=>{that.props.dispatch (getAttachmentForTaskStep (ev.step_id, ev.attachmentName))}}>{ev.attachmentName}</Button></div>:null}
                                    </div>
                                })}
                            </Col></Row>
                        : null}
                    </div>
                    <div>
                        <Row>
                            <Col md={12}>
                                {[['user', 'Responsible']].map (function (sel, ic) {
                                        return <div className="m-3" key={"sel_" + ic}>
                                                    <label className="form-label">{sel[1]}</label>
                                                    <div>
                                                        <Select 
                                                            menuPosition="fixed"
                                                            name={sel[0]}
                                                            id={sel[0]}
                                                            onChange={that.handleSelectChange}
                                                            value={that.state[sel[0]]}
                                                            options={[{value:0, label:"----"}].concat (selOptions[sel[0]])}
                                                        />
                                                    </div>
                                                </div>

                                    })}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Button className='m-3' variant="secondary" title={ (this.state.newComment.length < 3) ? "Please specify the comment":null }  style={{pointerEvents: "auto"}}  disabled={(this.state.newComment.length < 3)} onClick={(e)=>{ this.props.dispatch (apiRequestWithFiles({'endpoint': 'closeTask', "taskId": that.props.interface.editingTaskId, "newState":that.state}, that.state.selectedFile, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": null})) }}>
                                    Mark as closed
                                </Button>
                                <Button className='m-3' variant="secondary" onClick={(e)=>{ this.props.dispatch (apiRequestWithFiles({'endpoint': 'convertTaskToSale', "taskId": that.props.interface.editingTaskId, "newState":that.state}, that.state.selectedFile, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": null})) }}>
                                    Convert to transaction
                                </Button>
                                <Button className='m-3' variant="primary" title={ (!this.state.nextActionDateChanged) ? "Please set the next action date":null } style={{pointerEvents: "auto"}} disabled={(!this.state.nextActionDateChanged)} onClick={(e)=>{ this.props.dispatch (apiRequestWithFiles({'endpoint': 'editTask', "taskId": that.props.interface.editingTaskId, "newState":that.state}, that.state.selectedFile, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": null})) }}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                        
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        tasks: state.data.tasks,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        users: state.data.users,
        taskSteps: state.data.taskSteps,
        taskDetails: state.data.taskDetails,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(TaskEditModal)